
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-layers {
  -webkit-animation: filter-animation 4s infinite;
  animation: filter-animation 4s infinite;
}

.bg-scale {
  -webkit-animation: filter-animation 8s infinite;
  animation: filter-scale 8s infinite;
}

@-webkit-keyframes filter-animation {
  0% {
-webkit-filter: hue-rotate(0deg);
  }
  50% {
-webkit-filter: hue-rotate(45deg);
  }
  
  100% {
-webkit-filter: hue-rotate(0deg);
  }
}

@keyframes filter-animation {
  0% {
filter: hue-rotate(0deg);
  }
  50% {
filter: hue-rotate(45deg);
  }
  100% {
filter: hue-rotate(0deg);
  }
}

@keyframes filter-scale {
  0% {
transform:scale(1);
  }
  50% {
transform:scale(1.4);
  }
  100% {
transform:scale(.8);
  }
}